import React from 'react';
import { useRouter } from 'next/router';
import { Box, Container, Typography, InputBase, ContainerProps } from '@mui/material';
import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';
import { styled } from '@mui/material/styles';

import { useLocalizationContext } from '../LocalizationContext';

interface SearchBoxProps {
  isMain?: boolean;
}

export const SearchBox = ({ isMain = false }: SearchBoxProps) => {
  const router = useRouter() || { query: { query: '' } };
  const { query } = router;
  const [value, setValue] = React.useState('');
  const loaded = React.useRef(false);
  const localization = useLocalizationContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault();

    let queryValue = '';
    if (Array.isArray(value)) {
      queryValue = value.join(' ');
    } else if (typeof value === 'string') {
      queryValue = value;
    }
    if (!isMain) {
      if (queryValue.trim() !== '') router.push(`/blog?query=${queryValue}#search`);
      return;
    }

    if (queryValue?.trim() === '') {
      delete router.query.query;
    } else {
      router.query.query = queryValue;
      delete router.query.page;
    }

    const element = document.getElementById('search');

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }

    router.push(router, undefined, { shallow: true });
  };

  React.useEffect(() => {
    if (!query.query) return;
    if (query.query && !loaded.current && value === '') setValue(query.query as string);
    loaded.current = true;
  }, [query.query, value]);

  return (
    <ErrorBoundary>
      <Root
        py={2}
        sx={{ width: '100%', bgcolor: 'background.dark', display: 'flex', alignItems: 'center' }}
        data-testid="SearchBox">
        <ContainerRoot sx={{ display: 'flex' }} component="form" onSubmit={handleSubmit} maxWidth="xl">
          <Typography variant="h4" align="left" sx={{ color: 'secondary.main', flex: 1, mr: 3 }}>
            {localization['blog.searchTitle']?.shortTextValue ?? 'Blog'}
          </Typography>
          <Search
            placeholder={localization['blog.searchPlaceholder']?.shortTextValue ?? 'SEARCH'}
            inputProps={{ 'aria-label': 'search a blog' }}
            value={value}
            onChange={handleChange}
            data-testid="SearchBox-searchInput"
          />
        </ContainerRoot>
      </Root>
    </ErrorBoundary>
  );
};

export default SearchBox;

const Root = styled(Box, {
  name: 'SearchBox',
  slot: 'root'
})<{ variant?: string }>(() => ({}));

const ContainerRoot = styled(Container, {
  name: 'SearchBox',
  slot: 'containerRoot'
})<ContainerProps<React.ElementType>>(() => ({}));

const Search = styled(InputBase, {
  name: 'SearchBox',
  slot: 'input'
})<{ variant?: string }>(({ theme }) => ({
  'backgroundColor': theme.palette.grey[100],
  'color': theme.palette.common.black,
  'fontSize': 16,
  'fontWeight': 600,
  'paddingLeft': theme.spacing(3),
  'height': 40,
  'width': 300,
  'borderRadius': 20,
  'letterSpacing': '0.2rem',
  '& input': {
    '&::placeholder': {
      color: theme.palette.grey[200]
    }
  }
}));
