import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ContentModule from 'sensortower-components/src/base-components/ContentModule';

import SearchBox from '../../components/SearchBox';
import AnnouncementBanner from '../../components/AnnouncementBanner';
import { sidekick } from '../../utils/sidekick';

const PageGeneral = ({
  header,
  hero,
  contents,
  footer,
  showSearchBox,
  showCookieList,
  sidekickLookup
}: any & { __typename: string }) => {
  return (
    <div {...sidekick(sidekickLookup)}>
      <>
        {header ? <ContentModule {...(header as any)} colorScheme="Default" /> : null}
        <AnnouncementBanner />
        <div id="page-content">
          {showSearchBox && <SearchBox isMain />}
          {hero ? (
            <ContentModule key={hero?.id} {...(hero as any)} background={{ ...hero?.background, unoptimized: false }} />
          ) : null}
          {contents?.map((content: any, idx: number) => (
            <ContentModule key={content?.id ?? idx} {...(content as any)} component="section" />
          ))}
        </div>
        {showCookieList ? (
          <Container maxWidth="xl">
            <Grid item>
              <div id="ot-sdk-cookie-policy"></div>
            </Grid>
          </Container>
        ) : null}
        {footer ? <ContentModule {...(footer as any)} colorScheme="Default" /> : null}
      </>
    </div>
  );
};
export default PageGeneral;
