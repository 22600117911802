import React from 'react';
import { useRouter } from 'next/router';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';

const BannerContainer = styled(Box)(({ theme }) => ({
  // Hide the banner on smaller screens
  // [theme.breakpoints.down('lg')]: {
  //   display: 'none'
  // },
  alignItems: 'center',
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  display: 'flex',
  fontFamily: 'Outfit, sans-serif',
  fontSize: 20,
  fontWeight: 500,
  minHeight: 64,
  justifyContent: 'center'
}));

const BannerLink = styled('a')(() => ({
  'backgroundColor': '#FF006B',
  'borderRadius': 6,
  'color': '#ffffff',
  'fontFamily': 'Outfit, sans-serif',
  'fontSize': 16,
  'fontWeight': 500,
  'minHeight': 36,
  'lineHeight': '20px',
  'marginLeft': 16,
  'padding': '8px 16px 8px 16px',
  'textTransform': 'uppercase',
  '&:hover': {
    backgroundColor: '#E40060'
  }
}));

export const AnnouncementBanner = () => {
  const { locale } = useRouter();

  const announcementTexts = {
    'en-US': 'We’ve Acquired data.ai',
    'zh-CN': '我们已经收购了data.ai',
    'ja': '当社はdata.aiを買収しました',
    'ko': '센서타워, data.ai 인수'
  };
  const linkTexts = {
    'en-US': 'Learn more',
    'zh-CN': '了解详情',
    'ja': '詳細はこちら',
    'ko': '자세히 알아보기'
  };
  const linkHrefs = {
    'en-US': '/blog/data-ai-joins-sensor-tower',
    'zh-CN': '/zh-CN/blog/data-ai-joins-sensor-tower-cn',
    'ja': '/ja/blog/data-ai-joins-sensor-tower-jp',
    'ko': '/ko/blog/data-ai-joins-sensor-tower-kr'
  };
  const queryString =
    '?utm_source=website&utm_medium=website&utm_campaign=data-ai-joins-sensor-tower&utm_content=blog&utm_term=march18';

  // @ts-expect-error - TS doesn't know that the locale is a string
  const announcementText = announcementTexts[locale] || announcementTexts['en-US'];
  // @ts-expect-error - TS doesn't know that the locale is a string
  const linkText = linkTexts[locale] || linkTexts['en-US'];
  // @ts-expect-error - TS doesn't know that the locale is a string
  const linkHref = (linkHrefs[locale] || linkHrefs['en-US']) + queryString;

  return (
    <BannerContainer>
      <span>{announcementText}</span>
      <BannerLink href={linkHref}>{linkText}</BannerLink>
    </BannerContainer>
  );
};

export default AnnouncementBanner;
